<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <v-file-input
            v-model="files"
            show-size
            label="File input"
            placeholder="Select your files"
            prepend-icon="mdi-paperclip"
            outlined
            @change="handleFileSelect" 
            accept=".csv"
          >
          </v-file-input>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="search" label="Search" placeholder="Ex: Shoes" outlined></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" lg="3" md="4" offset-lg="3">
              <v-btn color="warning" block @click="downloadCsvtemplate()">Download Template</v-btn>
            </v-col>
            <v-col cols="12" lg="3" md="4">
              <v-btn color="primary" block @click="dialogVisible = !dialogVisible">Setting</v-btn>
            </v-col>
            <v-col cols="12" lg="3" md="4" v-if="checkfiles()">
              <v-btn color="success" block @click="downloadCsvProduct()">Download CSV Product</v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="csvData"
            :items-per-page="10"
            :search="search"
            :items-per-page-options="[10 ,50, 100, 200]"
          >
            <template #item.index="{ item }">
                {{ itemIndex(item) }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn color="primary" text @click="editItem(item)">
                View
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog 
      v-model="dialogVisible" 
      scrollable
      max-width="500px">
      <v-card>
        <v-card-title>
          Setting
        </v-card-title>
        <v-card-text>
          <v-subheader>VARIABLE</v-subheader>
          <v-spacer></v-spacer>
          <v-text-field v-model="defaultproduct.Tcategories" label="Categories" placeholder="Ex: Kids, Kids > Boy"></v-text-field>
          <v-text-field v-model="defaultproduct.Ttags" label="Tags" placeholder="Ex: kids, boy, shoes"></v-text-field>
          <v-select v-model="defaultproduct.Tpub" :items="options" label="Published" item-text="label" item-value="value"></v-select>
          <v-select v-model="defaultproduct.Tfeatured" :items="options" label="Is featured?" item-text="label" item-value="value"></v-select>
          <v-text-field v-model="defaultproduct.Tstatus" label="Tax status" placeholder="Ex: taxable"></v-text-field>
          <v-text-field v-model="defaultproduct.Tinstock" type="number" label="In stock?" placeholder="Ex: min 1"></v-text-field>
          <v-select v-model="defaultproduct.Tbackorders" :items="options" label="Backorders allowed?" item-text="label" item-value="value"></v-select>
          <v-select v-model="defaultproduct.Tsold" :items="options" label="Sold individually?" item-text="label" item-value="value"></v-select>
          <v-text-field v-model="defaultproduct.Tweight" type="number" label="Weight (kg)" placeholder="Ex: min 1"></v-text-field>
          <v-select v-model="defaultproduct.Treviews" :items="options" label="Allow customer reviews?" item-text="label" item-value="value"></v-select>
          <v-checkbox v-model="defaultproduct.TVarian" label="Does this product have variations such as size or alphabet size. check the one next to it to use it."></v-checkbox>
          <v-spacer></v-spacer>
          <v-subheader v-if="defaultproduct.TVarian">VARIATION</v-subheader>
          <v-spacer></v-spacer>
          <v-text-field v-model="defaultproduct.Taname2" v-if="defaultproduct.TVarian" label="Model Type" placeholder="Ex: Size"></v-text-field>
          <v-text-field v-model="defaultproduct.Tavalues2" v-if="defaultproduct.TVarian" label="Model Type Value" placeholder="Ex: XS, S, M, L, XL"></v-text-field>
          <v-text-field v-model="defaultproduct.Tadefault2" v-if="defaultproduct.TVarian" label="Model Type Default Value" placeholder="Ex: M"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="checkvarianclose()">Close Setting</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Papa from 'papaparse';

export default {
  data() {
    return {
      dialogVisible:false,
      search: '',
      files: null,
      csvData: [],
      headers: [],
      itemsPerPage: 5,
      page: 1,
      pageCount: 0,
      options: [
        { label: 'Off', value: 0 },
        { label: 'On', value: 1 },
      ],
      defaultproduct:{
        TVarian:false,
        Tcategories:'', // Kids > Boy
        Ttags:'', // kid, boy, sport
        Tpub:1,
        Tfeatured:0,
        Tcatalog:'visible',
        Tstatus:'taxable',
        Tinstock:1,
        Tbackorders:0,
        Tsold:0,
        Tweight:1,
        Treviews:0,
        Tparent:'',
        Tposition:0,
        Taname1:'Brand',
        Tavalues1:'Nama Brand',
        Tavisible1:1,
        Taglobal1:1,
        Tmtpb_post:'default',
        Tmtpb_page:'panggil brand',
        Taname2:'Size',
        Tavalues2:'', //[1,2,3,4]
        Tavisible2:1,
        Taglobal2:1,
        Tadefault2:'', // 2
      }
    };
  },
  computed: {
    totalItems() {
    return this.items.length;
    },
    startIndex() {
    return (this.page - 1) * this.itemsPerPage + 1;
    },
    endIndex() {
    return Math.min(this.page * this.itemsPerPage, this.totalItems);
    },
  },
  methods: {
    checkfiles(){
      return (this.files) ? true : false;
    },
    itemIndex(item) {
      return this.csvData.indexOf(item) + this.startIndex;
    },
    checkvarianclose(){
      if(this.defaultproduct.TVarian){
        let datax1 = this.defaultproduct.Taname2
        let datax2 = this.defaultproduct.Tavalues2
        let datax3 = this.defaultproduct.Tadefault2
        if(!datax1 || !datax2 || !datax3){
          alert('Variation data is still empty, please complete it.');
          return false;
        }else{
          this.dialogVisible = false;
        }
      }else{
        this.dialogVisible = false;
      }
      // return this.csvData.indexOf(item) + this.startIndex;
      this.handleFileSelect()
    },
    areArraysEqual(array1, array2) {
      if (array1.length !== array2.length){return false;}
      for (let i = 0; i < array1.length; i++) {
        if (array1[i] !== array2[i]) {
          return false;
        }
      }
      return true;
    },
    handleFileRemove() {
      this.csvData = []
      this.headers = []
    },
    handleFileSelect() {
      const file = this.files;
      if(!file){
        this.csvData = []
        this.headers = []
        return false
      }
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const newArr = [];
          for (let i = 0; i < results.data.length; i++) {
            const obj = results.data[i];
            const newObj = {};

            for (let key in obj) {
              const trimmedKey = key.trim();
              newObj[trimmedKey] = obj[key];
            }
            newArr.push(newObj);
          }
          // console.log(newArr)
          let xdata = Object.keys(newArr[0]);
          // console.log(xdata)
          let KeyStatus = this.areArraysEqual(xdata,['sku', 'product', 'brand', 'saleprice', 'regularprice', 'images'])
          if(KeyStatus){
            const loadheaders = [
                    {text:'Index',value:'index'},
                    // {text:'Action',value:'action'},
                    {text:'Type', value:'Type'},
                    {text:'SKU', value:'SKU'},
                    {text:'Name', value:'Name'},
                    {text:'Published', value:'Published'},
                    {text:'Is featured?', value:'Is featured?'},
                    {text:'Visibility in catalog', value:'Visibility in catalog'},
                    {text:'Tax status', value:'Tax status'},
                    {text:'In stock?', value:'In stock?'},
                    {text:'Backorders allowed?', value:'Backorders allowed?'},
                    {text:'Sold individually?', value:'Sold individually?'},
                    {text:'Weight (kg)', value:'Weight (kg)'},
                    {text:'Allow customer reviews?', value:'Allow customer reviews?'},
                    {text:'Sale price', value:'Sale price'},
                    {text:'Regular price', value:'Regular price'},
                    {text:'Categories', value:'Categories'},
                    {text:'Tags', value:'Tags'},
                    {text:'Images', value:'Images'},
                    {text:'Parent', value:'Parent'},
                    {text:'Position', value:'Position'},
                    {text:'Attribute 1 name', value:'Attribute 1 name'},
                    {text:'Attribute 1 value(s)', value:'Attribute 1 value(s)'},
                    {text:'Attribute 1 visible', value:'Attribute 1 visible'},
                    {text:'Attribute 1 global', value:'Attribute 1 global'},
                    {text:'Meta: _et_pb_post_hide_nav', value:'Meta: _et_pb_post_hide_nav'},
                    {text:'Meta: _et_pb_page_layout', value:'Meta: _et_pb_page_layout'},
                    {text:'Attribute 2 name', value:'Attribute 2 name'},
                    {text:'Attribute 2 value(s)', value:'Attribute 2 value(s)'},
                    {text:'Attribute 2 visible', value:'Attribute 2 visible'},
                    {text:'Attribute 2 global', value:'Attribute 2 global'},
                    {text:'Attribute 2 default', value:'Attribute 2 default'}
                  ];
            const formatarray = []
            let islength = newArr.length
            console.log(islength)
            let Y = 0;
            newArr.forEach((R) => {
              Y++;
              if(Y < islength){
                // Variabel
                formatarray.push({
                  'Type': (this.defaultproduct.Tavalues2 && this.defaultproduct.TVarian) ? 'variable' :'simple' ,
                  'SKU':R.sku,
                  'Name':R.product,
                  'Published':this.defaultproduct.Tpub,
                  'Is featured?':this.defaultproduct.Tfeatured,
                  'Visibility in catalog':this.defaultproduct.Tcatalog,
                  'Tax status':this.defaultproduct.Tstatus,
                  'In stock?':this.defaultproduct.Tinstock,
                  'Backorders allowed?':this.defaultproduct.Tbackorders,
                  'Sold individually?':this.defaultproduct.Tsold,
                  'Weight (kg)':this.defaultproduct.Tweight,
                  'Allow customer reviews?':this.defaultproduct.Treviews,
                  'Sale price':R.saleprice,
                  'Regular price':R.regularprice,
                  'Categories':this.defaultproduct.Tcategories,
                  'Tags':this.defaultproduct.Ttags,
                  'Images':R.images,
                  'Parent':this.defaultproduct.Tparent,
                  'Position':this.defaultproduct.Tposition,
                  'Attribute 1 name':'Brand',
                  'Attribute 1 value(s)':R.brand,
                  'Attribute 1 visible':this.defaultproduct.Tavisible1,
                  'Attribute 1 global':this.defaultproduct.Taglobal1,
                  'Meta: _et_pb_post_hide_nav':this.defaultproduct.Tmtpb_post,
                  'Meta: _et_pb_page_layout':R.brand,
                  'Attribute 2 name':this.defaultproduct.TVarian ? this.defaultproduct.Taname2 : '',
                  'Attribute 2 value(s)':this.defaultproduct.TVarian ? this.defaultproduct.Tavalues2 : '',
                  'Attribute 2 visible':this.defaultproduct.TVarian ? this.defaultproduct.Tavisible2 : '',
                  'Attribute 2 global':this.defaultproduct.TVarian ? this.defaultproduct.Taglobal2 : '',
                  'Attribute 2 default':this.defaultproduct.TVarian ? this.defaultproduct.Tadefault2 : ''
                })

                if(this.defaultproduct.Tavalues2 && this.defaultproduct.TVarian){
                  // XS, S, M, L, XL
                  const trimmedArray = this.defaultproduct.Tavalues2.trim().split(",").map(element => element.trim());
                  let varindex = 1;
                  // console.log(trimmedArray)
                  trimmedArray.forEach((P) => {
                    formatarray.push({
                      'Type':'variation',
                      'SKU':'',
                      'Name':R.product+' - '+P,
                      'Published':this.defaultproduct.Tpub,
                      'Is featured?':this.defaultproduct.Tfeatured,
                      'Visibility in catalog':this.defaultproduct.Tcatalog,
                      'Tax status':this.defaultproduct.Tstatus,
                      'In stock?':this.defaultproduct.Tinstock,
                      'Backorders allowed?':this.defaultproduct.Tbackorders,
                      'Sold individually?':this.defaultproduct.Tsold,
                      'Weight (kg)':this.defaultproduct.Tweight,
                      'Allow customer reviews?':this.defaultproduct.Treviews,
                      'Sale price':R.saleprice,
                      'Regular price':R.regularprice,
                      'Categories':this.defaultproduct.Tcategories,
                      'Tags':this.defaultproduct.Ttags,
                      'Images':R.images,
                      'Parent':R.sku,
                      'Position':varindex++,
                      'Attribute 1 name':this.defaultproduct.Taname2,
                      'Attribute 1 value(s)':this.defaultproduct.Tavalues2,
                      'Attribute 1 visible':this.defaultproduct.Tavisible1,
                      'Attribute 1 global':this.defaultproduct.Taglobal1,
                      'Meta: _et_pb_post_hide_nav':'',
                      'Meta: _et_pb_page_layout':R.brand,
                      'Attribute 2 name':'',
                      'Attribute 2 value(s)':'',
                      'Attribute 2 visible':'',
                      'Attribute 2 global':'',
                      'Attribute 2 default':''
                    })
                  })
                }
              }
            })
            // console.log(formatarray)
            
            this.csvData = formatarray;
            this.headers = loadheaders;

            // loadheaders.push({text:'Index',value:'index'})
            // xdata.forEach((x) => {
            //   loadheaders.push({text:x,value:x})
            // })
            // loadheaders.push({text:'Action',value:'action'})
          }else{
            alert('incompatible csv format!')
          }
        },
      });
    },
    convertToCsv(jsonData) {
      const csv = Papa.unparse(jsonData);
      return csv;
    },
    downloadCsv(csvData, fileName) {
      const csvBlob = new Blob([csvData], { type: 'text/csv' });
      const csvUrl = URL.createObjectURL(csvBlob);

      const link = document.createElement('a');
      link.setAttribute('href', csvUrl);
      link.setAttribute('download', fileName);
      link.style.display = 'none';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    downloadCsvtemplate() {
      let datax = [{
        sku:'',
        product:'',
        brand:'',
        saleprice:'',
        regularprice:'',
        images:''
      }];

      const csvData = this.convertToCsv(datax);
      const fileName = 'template_import_product_woocomers.csv';
      this.downloadCsv(csvData, fileName);
    },
    downloadCsvProduct() {
      const csvData = this.convertToCsv(this.csvData);
      const fileName = 'generate_product_woocomers.csv';
      this.downloadCsv(csvData, fileName);
    }
  },
};
</script>